<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>
          <strong>
          </strong> Warehouses
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-text-field
              v-model="search"
              label="Search"
              single-line
              hide-details

          ></v-text-field>
        </v-card-title>
<v-col></v-col>
        <v-data-table
            :headers="headers"
            :items="warehouses"
            item-key="id"
            class="elevation-1 table-one"
            multi-sort
            :search="search"
            v-model="selectedRows"
            show-select
        >
          <template v-slot:item.name="{item}">
            <div class="d-flex align-center">
              <p class="ma-0 font-weight-medium">
                {{ item.name }}
              </p>
            </div>
          </template>
          <template v-slot:item.action="{item}">
            <div class="d-flex">

                <v-btn
                    color="green"
                    dark
                    outlined
                    @click.stop="viewWarehouse(item.id)">
                  View
                </v-btn>
                <v-btn
                    color="red"
                    dark
                    outlined
                    @click="delRecord(item.id)">
                  Delete
                </v-btn>

            </div>
          </template>
        </v-data-table>
      </base-card>
    </v-col>

    <ConfirmDialog ref="confirmDelete" />
  </v-row>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import ConfirmDialog from "@/components/shared/ConfirmDialog";
import { saveAs } from 'file-saver';


export default {
  name: "Warehouses",
  components: {
    ConfirmDialog,
  },
  data() {
    return {
      // url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      // zoom: 18,
      // center: [51.48819, -0.31524],
      // bounds: null,
      // editing: {},
      // sensors: [],
      repLabelCustomer: '',
      repLabelSite: '',
      repLabelDate: '',
      search: '',
      items: [],
      selectedRows: [],
      headers: [
        {
          text: 'Name',
          sortable: true,
          value: 'name',
          align: 'left',
        },
        {
          text: 'Description',
          sortable: true,
          value: 'description',
          align: 'left',
        },
        {
          text: 'Partner',
          sortable: true,
          value: 'partner.name',
          align: 'left',
        },
        {
          text: 'Address1',
          sortable: true,
          value: 'address1',
          align: 'middle',
        },
        {
          text: 'City',
          sortable: true,
          value: 'town',
          align: 'middle',
        },
        {
          text: 'Actions',
          value: 'action',
        }
      ],
    }
  },
  computed: {
    ...mapState('warehouses', {loadingWarehouses : 'isFindPending'}),
    ...mapGetters('warehouses', {findWarehousesInStore : 'find'}),

    warehouses() {
      return this.findWarehousesInStore({ query: {warehouse_status: { $ne: 'stock' }, archived: { $ne: 1 }}}).data || [];
    },
    selectedWarehouseIds() {
      return this.selectedRows.map(a => a.id);
    }
  },
  methods: {
    ...mapActions('warehouses', { findWarehouses: 'find' }),
    deleteItem(id) {
      this.$store.dispatch('warehouses/remove', id)
    },
    async delRecord(id) {
      if (
          await this.$refs.confirmDelete.open(
              "Confirm",
              "Are you sure you want to delete this record?"
          )
      ) {
        this.deleteItem(id);
      }
    },
    async onInit (promise) {
      // show loading indicator

      try {
        const { capabilities } = await promise

        // successfully initialized
      } catch (error) {
        alert(error.name);
        if (error.name === 'NotAllowedError') {
          // user denied camera access permisson
        } else if (error.name === 'NotFoundError') {
          // no suitable camera warehouse installed
        } else if (error.name === 'NotSupportedError') {
          // page is not served over HTTPS (or localhost)
        } else if (error.name === 'NotReadableError') {
          // maybe camera is already in use
        } else if (error.name === 'OverconstrainedError') {
          // did you requested the front camera although there is none?
        } else if (error.name === 'StreamApiNotSupportedError') {
          // browser seems to be lacking features
        }
      } finally {
        // hide loading indicator
      }
    },
    onDecode (result) {
      this.result = result
      alert(result);
    },
    viewWarehouse(id) {
      this.$router.push('/app/warehouseview/'+id)
    },

    async getOM(){
      saveAs(await ( await fetch(process.env.VUE_APP_FEATHERS_WS_URL+'api/reports/om?' + new URLSearchParams({
        customer: this.repLabelCustomer,
        site: this.repLabelSite,
        date: this.repLabelDate,
        fileName: 'coolnomixOM',
        warehouseIds: this.selectedWarehouseIds
      }), {
        method: 'GET',
        headers: {"Authorization": "Bearer " + this.$store.state.auth.accessToken}
      })).blob(), 'coolnomixOM.pdf');
    },
  },
  mounted() {
    this.findWarehouses();
    if (localStorage.warehousesIndexSearch) {
      this.search = localStorage.warehousesIndexSearch;
    }
  },
  watch: {
    search(newSearchTerm) {
      localStorage.warehousesIndexSearch = newSearchTerm;
    }
  },
}
</script>

<style scoped>

</style>
